<template>
  <div>
    <c-card title="훈련 시나리오 정보" class="cardClassDetailForm" :collapsed="true">
      <template slot="card-detail">
        <div class="col-sm-12 col-md-3 col-lg-3">
          <c-text 
            :afterIcon="editable && !disabled ? [
              { name: 'search', click: true, callbackName: 'searchTraningScenario', color: 'teal' },
              { name: 'close', click: true, callbackName: 'removeTraningScenario', color: 'red' },
            ] : null"
            :editable="editable"
            :disabled="true"
            label="훈련시나리오명"
            name="trainingScenarioName"
            v-model="traning.trainingScenarioName"
            @searchTraningScenario="searchTraningScenario"
            @removeTraningScenario="removeTraningScenario">
          </c-text>
        </div>
        <div class="col-sm-6 col-md-3 col-lg-3">
          <c-text
            :editable="editable"
            :disabled="true"
            label="Rev."
            name="revisionNum"
            v-model="traning.revisionNum">
          </c-text>
        </div>
        <div class="col-sm-6 col-md-3 col-lg-3">
          <c-text
            :editable="editable"
            :disabled="true"
            label="비상조치계획서명"
            name="emergencyPlanName"
            v-model="traning.emergencyPlanName">
          </c-text>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6">
          <c-textarea
            :editable="editable"
            :disabled="true"
            :rows="2"
            label="훈련내용요약"
            name="trainingContentsSummary"
            v-model="traning.trainingContentsSummary">
          </c-textarea>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6">
          <c-textarea
            :editable="editable"
            :disabled="true"
            :rows="2"
            label="훈련시나리오개요"
            name="trainingContentsOutline"
            v-model="traning.trainingContentsOutline">
          </c-textarea>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6">
          <c-textarea
            :editable="editable"
            :disabled="true"
            :rows="2"
            label="직접참가자 유의사항"
            name="directlyNotice"
            v-model="traning.directlyNotice">
          </c-textarea>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6">
          <c-textarea
            :editable="editable"
            :disabled="true"
            :rows="2"
            label="간접참가자 유의사항"
            name="indirectNotice"
            v-model="traning.indirectNotice">
          </c-textarea>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6">
          <c-textarea
            :editable="editable"
            :disabled="true"
            :rows="2"
            label="준비물"
            name="materials"
            v-model="traning.materials">
          </c-textarea>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6">
          <c-textarea
            :editable="editable"
            :disabled="true"
            :rows="2"
            label="사전준비사항"
            name="preRequisites"
            v-model="traning.preRequisites">
          </c-textarea>
        </div>
      </template>
    </c-card>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
export default {
  name: 'traning-scenario-info',
  props: {
    traning: {
      type: Object,
      default: () => ({
        sopEmergencyTrainingId: '',
        trainingScenarioName: '',
        preRequisites: '',
        materials: '',
        indirectNotice: '',
        directlyNotice: '',
        trainingContentsOutline: '',
        trainingContentsSummary: '',
        revisionNum: '',

      }),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      editable: true,
      detailUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        param: {},
        closeCallback: null,
      },
      saveCall: {
        saveCallData: '',
      },
      getUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      // url setting
      // list setting
    },
    searchTraningScenario() {
      this.popupOptions.title = '비상조치계획서 검색'; // 관련 공정사고 검색
      this.popupOptions.param = {
        type: 'single'
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/eap/emergencyActionPlanDocuPop'}`);
      this.popupOptions.width = '70%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeTraningScenarioPopup;
    },
    /* eslint-disable no-unused-vars */ 
    closeTraningScenarioPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.traning.emergencyPlanName = data[0].emergencyPlanName
        this.traning.trainingScenarioName = data[0].trainingScenarioName
        this.traning.trainingContentsSummary = data[0].trainingContentsSummary
        this.traning.preRequisites = data[0].preRequisites
        this.traning.revisionNum = data[0].revisionNum
        this.traning.directlyNotice = data[0].directlyNotice
        this.traning.indirectNotice = data[0].indirectNotice
        this.traning.trainingContentsOutline = data[0].trainingContentsOutline
        this.traning.materials = data[0].materials
        this.traning.sopEmergencyPlanId = data[0].sopEmergencyPlanId
        this.traning.trainingScenarioNo = data[0].trainingScenarioNo
      }
    },
    removeTraningScenario() {
      this.traning.trainingScenarioName = '';
      this.traning.trainingContentsSummary = '';
      this.traning.preRequisites = '';
      this.traning.revisionNum = '';
      this.traning.directlyNotice = '';
      this.traning.indirectNotice = '';
      this.traning.trainingContentsOutline = '';
      this.traning.materials = '';
      this.traning.sopEmergencyPlanId = '';
      this.traning.trainingScenarioNo = '';
    },
  }
};
</script>
