var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-card",
        {
          staticClass: "cardClassDetailForm",
          attrs: { title: "훈련 시나리오 정보", collapsed: true },
        },
        [
          _c("template", { slot: "card-detail" }, [
            _c(
              "div",
              { staticClass: "col-sm-12 col-md-3 col-lg-3" },
              [
                _c("c-text", {
                  attrs: {
                    afterIcon:
                      _vm.editable && !_vm.disabled
                        ? [
                            {
                              name: "search",
                              click: true,
                              callbackName: "searchTraningScenario",
                              color: "teal",
                            },
                            {
                              name: "close",
                              click: true,
                              callbackName: "removeTraningScenario",
                              color: "red",
                            },
                          ]
                        : null,
                    editable: _vm.editable,
                    disabled: true,
                    label: "훈련시나리오명",
                    name: "trainingScenarioName",
                  },
                  on: {
                    searchTraningScenario: _vm.searchTraningScenario,
                    removeTraningScenario: _vm.removeTraningScenario,
                  },
                  model: {
                    value: _vm.traning.trainingScenarioName,
                    callback: function ($$v) {
                      _vm.$set(_vm.traning, "trainingScenarioName", $$v)
                    },
                    expression: "traning.trainingScenarioName",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-sm-6 col-md-3 col-lg-3" },
              [
                _c("c-text", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: true,
                    label: "Rev.",
                    name: "revisionNum",
                  },
                  model: {
                    value: _vm.traning.revisionNum,
                    callback: function ($$v) {
                      _vm.$set(_vm.traning, "revisionNum", $$v)
                    },
                    expression: "traning.revisionNum",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-sm-6 col-md-3 col-lg-3" },
              [
                _c("c-text", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: true,
                    label: "비상조치계획서명",
                    name: "emergencyPlanName",
                  },
                  model: {
                    value: _vm.traning.emergencyPlanName,
                    callback: function ($$v) {
                      _vm.$set(_vm.traning, "emergencyPlanName", $$v)
                    },
                    expression: "traning.emergencyPlanName",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-sm-12 col-md-6 col-lg-6" },
              [
                _c("c-textarea", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: true,
                    rows: 2,
                    label: "훈련내용요약",
                    name: "trainingContentsSummary",
                  },
                  model: {
                    value: _vm.traning.trainingContentsSummary,
                    callback: function ($$v) {
                      _vm.$set(_vm.traning, "trainingContentsSummary", $$v)
                    },
                    expression: "traning.trainingContentsSummary",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-sm-12 col-md-6 col-lg-6" },
              [
                _c("c-textarea", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: true,
                    rows: 2,
                    label: "훈련시나리오개요",
                    name: "trainingContentsOutline",
                  },
                  model: {
                    value: _vm.traning.trainingContentsOutline,
                    callback: function ($$v) {
                      _vm.$set(_vm.traning, "trainingContentsOutline", $$v)
                    },
                    expression: "traning.trainingContentsOutline",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-sm-12 col-md-6 col-lg-6" },
              [
                _c("c-textarea", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: true,
                    rows: 2,
                    label: "직접참가자 유의사항",
                    name: "directlyNotice",
                  },
                  model: {
                    value: _vm.traning.directlyNotice,
                    callback: function ($$v) {
                      _vm.$set(_vm.traning, "directlyNotice", $$v)
                    },
                    expression: "traning.directlyNotice",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-sm-12 col-md-6 col-lg-6" },
              [
                _c("c-textarea", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: true,
                    rows: 2,
                    label: "간접참가자 유의사항",
                    name: "indirectNotice",
                  },
                  model: {
                    value: _vm.traning.indirectNotice,
                    callback: function ($$v) {
                      _vm.$set(_vm.traning, "indirectNotice", $$v)
                    },
                    expression: "traning.indirectNotice",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-sm-12 col-md-6 col-lg-6" },
              [
                _c("c-textarea", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: true,
                    rows: 2,
                    label: "준비물",
                    name: "materials",
                  },
                  model: {
                    value: _vm.traning.materials,
                    callback: function ($$v) {
                      _vm.$set(_vm.traning, "materials", $$v)
                    },
                    expression: "traning.materials",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-sm-12 col-md-6 col-lg-6" },
              [
                _c("c-textarea", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: true,
                    rows: 2,
                    label: "사전준비사항",
                    name: "preRequisites",
                  },
                  model: {
                    value: _vm.traning.preRequisites,
                    callback: function ($$v) {
                      _vm.$set(_vm.traning, "preRequisites", $$v)
                    },
                    expression: "traning.preRequisites",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }